import * as React from "react"
import { graphql, navigate } from "gatsby"

import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MagazineDocument from "../components/magazine"

const MercuryPage = ({ data }) => {
  const [authenticated, setAuthenticated] = React.useState(true)
  const [password, setPassword] = React.useState("")
  const [incorrectPassword, setIncorrectPassword] = React.useState(false)
  const { collections, title } = data.allMarkdownRemark.nodes[0].frontmatter

  const handleSubmit = () => {
    if (password === "test") {
      setIncorrectPassword(false)
      setAuthenticated(true)
      setPassword("")
    } else {
      setIncorrectPassword(true)
      setAuthenticated(false)
      setPassword("")
    }
  }
  const handleBack = () => navigate(-1)

  return (
    <>
      <Layout title={title}>
        <Seo title={title} />

        <Modal show={!authenticated} onHide={handleSubmit} centered>
          <Modal.Header>
            <Modal.Title className="mx-auto">Password required!</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-start">
            <form>
              <label>
                {incorrectPassword && <p className="text-danger mb-0">Incorrect password!</p>}
                Input the password:
                <input
                  className="w-100"
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </label>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="me-auto"
              onClick={handleBack}
            >
              Go Back
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>

        <div className="flex-fill">
          {authenticated &&
            collections.map((collection, i) => (
              <MagazineDocument key={collection.title} {...collection} />
            ))}
        </div>
      </Layout>
    </>
  )
}

export default MercuryPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(mercury.md)/" } }
    ) {
      nodes {
        id
        html
        frontmatter {
          title
          collections {
            title
            file
            buy
            pages
          }
        }
      }
    }
  }
`
